import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			pageName: "Уведомления об операциях",
			desktopTitle: <>Самое важное&nbsp;&mdash; сразу в&nbsp;твоём телефоне</>,
			subTitle: (
				<p className={styles.description}>
					Наблюдай за&nbsp;бизнесом в&nbsp;реальном времени: настрой уведомления
					о&nbsp;выручке за&nbsp;день и&nbsp;возвратах чеков. Следи
					за&nbsp;количеством гостей и&nbsp;эффективностью официантов.
				</p>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					alt="уведомления о возврате чека"
					src="./assets/notifications-presentation-by.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Хабарламалар",
			desktopTitle: <>Ең маңыздысы - бірден сіздің телефоныңызда</>,
			subTitle: (
				<p className={styles.description}>
					Бизнесті нақты уақытта бақылаңыз: күндегі түсім және чектерді қайтару туралы хабарламаларды теңшеңіз. Қонақтар санын және даяшылар тиімділігін қадағалаңыз.
				</p>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін ",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					alt="уведомления о возврате чека"
					src="./assets/notifications-presentation-kz.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'ru-KZ') {
		return {
			pageName: "Уведомления об операциях",
			desktopTitle: <>Самое важное&nbsp;&mdash; сразу в&nbsp;твоём телефоне</>,
			subTitle: (
				<p className={styles.description}>
					Наблюдай за&nbsp;бизнесом в&nbsp;реальном времени: настрой уведомления
					о&nbsp;выручке за&nbsp;день и&nbsp;возвратах чеков. Следи
					за&nbsp;количеством гостей и&nbsp;эффективностью официантов.
				</p>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					alt="уведомления о возврате чека"
					src="./assets/notifications-presentation-kz.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	return {
		pageName: "Уведомления об операциях",
		desktopTitle: <>Самое важное&nbsp;&mdash; сразу в&nbsp;твоём телефоне</>,
		subTitle: (
			<p className={styles.description}>
				Наблюдай за&nbsp;бизнесом в&nbsp;реальном времени: настрой уведомления
				о&nbsp;выручке за&nbsp;день и&nbsp;возвратах чеков. Следи
				за&nbsp;количеством гостей и&nbsp;эффективностью официантов.
			</p>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				className={styles.image}
				alt="уведомления о возврате чека"
				src="./assets/notifications-presentation.png"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	}
}
