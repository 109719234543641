import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/notifications.module.scss'

import NotificationsProductPresentation from '../pagesSections/notifications/ProductPresentation'
import StockFeatures from '../pagesSections/notifications/StockFeatures'
import AppFeatures from '../pagesSections/notifications/AppFeatures'

import Advantages from '../components/_V2/Advantages'
import CtaForm from '../pagesSections/index/CtaForm'
import BusinessTypes from '../components/_V2/BusinessTypes'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import Presentation from '../pagesSections/notifications/ProductPresentation/assets/notifications-presentation.png'
import PresentationKz from '../pagesSections/notifications/ProductPresentation/assets/notifications-presentation-kz.png'
import PresentationBy from '../pagesSections/notifications/ProductPresentation/assets/notifications-presentation-by.png'

import { getData as notificationsAdvantages } from '../pages-data/_V2/notifications/advantages'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { externalLinks } from '../pages-data/_V2/common/links'

import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function NotificationsPage() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Уведомления о возвратах и остатках в кафе | Quick Resto',
    'ru-KZ': 'Уведомления о возвратах и остатках в кафе | Quick Resto Казахстан',
    'kz-KZ': 'Уведомления о возвратах и остатках в кафе | Quick Resto Казахстан',
    'ru-BY': 'Уведомления о возвратах и остатках в кафе | Quick Resto Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}notifications/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}notifications/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}notifications/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}notifications/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Уведомления о выручке за день и сомнительных операциях  ✔ Отслеживание количества гостей и эффективности официантов ✔ Контроль сотрудников ✔ Quick Resto Manager и Telegram → Внедрение под ключ',
    'ru-KZ': 'Уведомления о выручке за день и сомнительных операциях ✔ Отслеживание количества гостей и эффективности официантов ✔ Контроль сотрудников ✔ Quick Resto Manager и Telegram → Внедрение под ключ. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Уведомления о выручке за день и сомнительных операциях ✔ Отслеживание количества гостей и эффективности официантов ✔ Контроль сотрудников ✔ Quick Resto Manager и Telegram → Внедрение под ключ. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Уведомления о выручке за день и сомнительных операциях ✔ Отслеживание количества гостей и эффективности официантов ✔ Контроль сотрудников ✔ Quick Resto Manager и Telegram → Внедрение под ключ. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Presentation}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresentationBy}`
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale],
    },
  ]
  return (
    <DefaultLayout
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={pageStyles.pageWrapper}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={currentTitle}
      schemaDescription={metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <NotificationsProductPresentation />

      <Advantages
        items={notificationsAdvantages(localizationContext.locale)}
        className={classNames(
				  styles.notifications__advantages,
				  pageStyles.pageSection,
        )}
      />

      <StockFeatures className={pageStyles.pageSection} />

      <AppFeatures className={pageStyles.pageSection} />

      <AdditionalServices
        className={classNames(
				  pageStyles.pageSection,
				  styles.notifications__services,
        )}
      />

      <CtaForm isFry={false} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
