import React from "react"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY'){
		return [
			{
				title: "Контроль сотрудников",
				description:
					"Отмена чека, возврат&nbsp;&mdash; тригерные события, которые стоит перепроверить.",
			},
			{
				title: "Гибкие настройки",
				description:
					"Можно настроить, какие именно уведомления хочешь получать и&nbsp;в&nbsp;каком канале: приложение, почта или Телеграм.",
			},
			{
				title: "Бесплатно",
				description:
					"Нет дополнительных расходов, включено в&nbsp;общую подписку на&nbsp;Quick Resto.",
			},
		]
	}

	if (locale === 'kz-KZ'){
		return [
			{
				title: "Қызметкерлерді бақылау",
				description:
					"Чекті болдырмау, қайтару - қайта тексеруге жататын триггерлік оқиғалар.",
			},
			{
				title: "Икемді теңшелімдер",
				description:
					"Нақты қандай хабарламаларды және қандай арнада: қосымшада, поштада немесе Телеграмда алғыңыз келетінді теңшеуге болады.",
			},
			{
				title: "Тегін",
				description:
					"Қосымша шығыстар жоқ, Quick Resto-да жалпы жазылымға қосылған.",
			},
		]
	}

	if (locale === 'ru-KZ'){
		return [
			{
				title: "Контроль сотрудников",
				description:
					"Отмена чека, возврат&nbsp;&mdash; тригерные события, которые стоит перепроверить.",
			},
			{
				title: "Гибкие настройки",
				description:
					"Можно настроить, какие именно уведомления хочешь получать и&nbsp;в&nbsp;каком канале: приложение, почта или Телеграм.",
			},
			{
				title: "Бесплатно",
				description:
					"Нет дополнительных расходов, включено в&nbsp;общую подписку на&nbsp;Quick Resto.",
			},
		]
	}

	return [
		{
			title: "Контроль сотрудников",
			description:
				"Отмена чека, возврат&nbsp;&mdash; тригерные события, которые стоит перепроверить.",
		},
		{
			title: "Гибкие настройки",
			description:
				"Можно настроить, какие именно уведомления хочешь получать и&nbsp;в&nbsp;каком канале: приложение, почта или Телеграм.",
		},
		{
			title: "Бесплатно",
			description:
				"Нет дополнительных расходов, включено в&nbsp;общую подписку на&nbsp;Quick Resto.",
		},
	]
}
