import React, {useEffect} from "react"
import classNames from "classnames"

import FeaturesGallery, {
	FEATURE_GALLERY_DIRECTION,
} from "../../../components/_V2/FeaturesGallery"

import styles from "./styles.module.scss"

import { getData } from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";


interface Props {
	className?: string
}

export default function AppFeatures(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	let features = data.features;

	useEffect(() => {
		features = data.features;
	}, [data]);
	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				theme={"cyan-custom-backdrop"}
				className={styles.featureGallery}
				direction={FEATURE_GALLERY_DIRECTION.REVERSE}
				btnStyleLink={true}
				features={features}
			/>
		</section>
	)
}
