import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"
import { externalLinks, pagesLinks } from "../../../pages-data/_V2/common/links"

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg"
import mediaContent from "./mediaContent.module.scss"
import styles from "./styles.module.scss"
import classNames from "classnames"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales): {
	features: Array<FeatureGalleryItem>
} => {
	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 508,
					tabName: "Приложение для руководителя",
					desktopTitle: "Quick Resto Manager",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Мобильное приложение для владельца ресторана, кафе или других точек
							общепита&nbsp;&mdash; следи за&nbsp;продажами, быстро реагируй
							на&nbsp;сомнительные операции по&nbsp;кассе, анализируй&nbsp;показатели.
							Делай правильные выводы. Приложение предоставляет статистику
							по&nbsp;бизнесу и&nbsp;сотрудникам, отправляет уведомления.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-app-1-by.png"
							alt={"quick resto manager"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={96}
						/>
					),
					detailLink: pagesLinks.indexQRM,
					backdropContent: <div className={styles.backdrop} />,
					bottomContent: (
						<div className={styles.notes}>
							<a
								href={externalLinks.appstoreQRM}
								className={classNames(styles.note, styles.link)}
								target="_blank"
							>
								<StaticImage
									src="../../../assets/images/app-store.png"
									alt=""
									objectFit="contain"
									placeholder="blurred"
									quality={90}
								/>
							</a>
							<a
								href={externalLinks.googlePlayQRM}
								className={classNames(styles.note, styles.link)}
								target="_blank"
							>
								<StaticImage
									src="../../../assets/images/google-play.png"
									alt=""
									objectFit="contain"
									placeholder="blurred"
									quality={90}
								/>
							</a>
						</div>
					),
				},
				{
					desktopContentWidth: 508,
					tabName: "Telegram бот",
					desktopTitle: <>Уведомления в&nbsp;Telegram</>,
					desktopContent: (
						<p>
							Привычный интерфейс, сообщения которые можно переслать коллегам.
							В&nbsp;любой момент можно обратиться к&nbsp;данным из&nbsp;системы
							управления и&nbsp;настроить формат&nbsp;уведомлений.
						</p>
					),
					tabContainerClass: mediaContent.tab,
					mediaContent: (
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image__tg_by)}
							src="./assets/notifications-app-2-by.png"
							alt={"уведомления в телеграм"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 508,
					tabName: "Басшыға арналған қосымша",
					desktopTitle: "Quick Resto Manager",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Мейрамхана, кафе немесе басқа тқоғамдық тамақтандыру нүктелеріне арналған мобильді қосымша
							- сатуды қадағалаңыз, касса бойынша күмәнді операцияларға тез жауап қайтарыңыз, көрсеткіштерді
							талдаңыз. Дұрыс қорытынды жасаңыз. Қосымша бизнес пен қызметкерлер бойынша статистиканы ұсынады,
							хабарламалар жібереді.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-app-1-kz.png"
							alt={"quick resto manager"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailLink: pagesLinks.indexQRM,
					backdropContent: <div className={styles.backdrop} />,
					bottomContent: (
						<div className={styles.notes}>
							<a
								href={externalLinks.appstoreQRM}
								className={classNames(styles.note, styles.link)}
								target="_blank"
							>
								<StaticImage
									src="../../../assets/images/app-store.png"
									alt=""
									objectFit="contain"
									placeholder="blurred"
									quality={90}
								/>
							</a>
							<a
								href={externalLinks.googlePlayQRM}
								className={classNames(styles.note, styles.link)}
								target="_blank"
							>
								<StaticImage
									src="../../../assets/images/google-play.png"
									alt=""
									objectFit="contain"
									placeholder="blurred"
									quality={90}
								/>
							</a>
						</div>
					),
				},
				{
					desktopContentWidth: 508,
					tabName: "Telegram бот",
					tabContainerClass: mediaContent.tab,
					desktopTitle: (
						<>
							Telegram-да хабарламалар
						</>
					),
					desktopContent: (
						<p>
							Үйреншікті интерфейс, әріптестерге қайта жіберуге болатын хабарламалар. Кез келген сәтте
							басқару жүйесінен деректерге жүгінуге және хабарламалар форматын теңшеуге болады.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image__tg_by)}
							src="./assets/notifications-app-2-kz.png"
							alt={"уведомления в телеграм"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={96}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 508,
					tabName: "Приложение для руководителя",
					desktopTitle: "Quick Resto Manager",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Мобильное приложение для владельца ресторана, кафе или других точек
							общепита&nbsp;&mdash; следи за&nbsp;продажами, быстро реагируй
							на&nbsp;сомнительные операции по&nbsp;кассе, анализируй&nbsp;показатели.
							Делай правильные выводы. Приложение предоставляет статистику
							по&nbsp;бизнесу и&nbsp;сотрудникам, отправляет уведомления.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-app-1-kz.png"
							alt={"quick resto manager"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailLink: pagesLinks.indexQRM,
					backdropContent: <div className={styles.backdrop} />,
					bottomContent: (
						<div className={styles.notes}>
							<a
								href={externalLinks.appstoreQRM}
								className={classNames(styles.note, styles.link)}
								target="_blank"
							>
								<StaticImage
									src="../../../assets/images/app-store.png"
									alt=""
									objectFit="contain"
									placeholder="blurred"
									quality={90}
								/>
							</a>
							<a
								href={externalLinks.googlePlayQRM}
								className={classNames(styles.note, styles.link)}
								target="_blank"
							>
								<StaticImage
									src="../../../assets/images/google-play.png"
									alt=""
									objectFit="contain"
									placeholder="blurred"
									quality={90}
								/>
							</a>
						</div>
					),
				},
				{
					desktopContentWidth: 508,
					tabName: "Telegram бот",
					tabContainerClass: mediaContent.tab,
					desktopTitle: (
						<>
							Быстрые <br />
							уведомления
						</>
					),
					tabletTitle: <>Уведомления в&nbsp;Telegram</>,
					mobileTitle: <>Уведомления в&nbsp;Telegram</>,
					desktopContent: (
						<p>
							Привычный интерфейс, сообщения которые можно переслать коллегам.
							В&nbsp;любой момент можно обратиться к&nbsp;данным из&nbsp;системы
							управления и&nbsp;настроить формат уведомлений.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image__tg_by)}
							src="./assets/notifications-app-2-kz.png"
							alt={"уведомления в телеграм"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={96}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 508,
				tabName: "Приложение для руководителя",
				desktopTitle: "Quick Resto Manager",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Мобильное приложение для владельца ресторана, кафе или других точек
						общепита&nbsp;&mdash; следи за&nbsp;продажами, быстро реагируй
						на&nbsp;сомнительные операции по&nbsp;кассе, анализируй показатели.
						Делай правильные выводы. Приложение предоставляет статистику
						по&nbsp;бизнесу и&nbsp;сотрудникам, отправляет уведомления.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/notifications-app-1.png"
						alt={"quick resto manager"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				detailLink: pagesLinks.indexQRM,
				backdropContent: <div className={styles.backdrop} />,
				bottomContent: (
					<div className={styles.notes}>
						<a
							href={externalLinks.appstoreQRM}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<StaticImage
								src="../../../assets/images/app-store.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
						<a
							href={externalLinks.googlePlayQRM}
							className={classNames(styles.note, styles.link)}
							target="_blank"
						>
							<StaticImage
								src="../../../assets/images/google-play.png"
								alt=""
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</a>
					</div>
				),
			},
			{
				desktopContentWidth: 508,
				tabName: "Telegram бот",
				tabContainerClass: mediaContent.tab,
				desktopTitle: <>Уведомления в&nbsp;Telegram</>,
				desktopContent: (
					<p>
						Привычный интерфейс, сообщения которые можно переслать коллегам.
						В&nbsp;любой момент можно обратиться к&nbsp;данным из&nbsp;системы
						управления и&nbsp;настроить формат уведомлений.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={classNames(mediaContent.image, mediaContent.image__tg)}
						src="./assets/notifications-app-2.png"
						alt={"уведомления в телеграм"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
			},
		] as Array<FeatureGalleryItem>,
	}
}
