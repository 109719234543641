import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg"

import mediaContent from "./mediaContent.module.scss"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales): {
	features: Array<FeatureGalleryItem>
} => {
	if (locale === 'ru-BY'){
		return {
			features: [
				{
					desktopContentWidth: 600,
					tabName: "Касса",
					desktopTitle: "Первое, что нужно включить",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Моментальные оповещения об&nbsp;операциях на&nbsp;кассе, открытии
							и&nbsp;закрытии смены, финансовых показателях рабочего дня.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-stock-1-by.png"
							alt={"оповещения об операциях на кассе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 600,
					tabName: "Склад",
					desktopTitle: (
						<>
							Без просадок <br />
							и&nbsp;стоп-листов
						</>
					),
					tabletTitle: <>Без просадок и&nbsp;стоп-листов</>,
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Для любого продукта можно установить порог по&nbsp;наличию
							на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
							сообщим, что ингредиент заканчивается. Так ты&nbsp;вовремя пополнишь
							запасы и&nbsp;не&nbsp;потеряешь продажи.{" "}
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-stock-2-by.png"
							alt={"уведомления об остатках на складе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Персонал",
					desktopTitle: (
						<>
							Если кто-то <br />
							не молодец
						</>
					),
					tabletTitle: <>Если кто-то не&nbsp;молодец</>,
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							В&nbsp;случае отмены заказа пришлём тебе уведомление в&nbsp;приложении
							или Telegram. Когда отмен слишком много&nbsp;&mdash; это повод
							проверить квалификацию или честность кассира.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/notifications-stock-3-by.png"
								alt={"уведомление об отмене заказа"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'kz-KZ'){
		return {
			features: [
				{
					desktopContentWidth: 600,
					tabName: "Касса",
					desktopTitle: "Қосуға жататын бірінші нәрсе",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Кассадағы күмәнді операциялар, ауысымның ашылуы және жабылуы, жұмыс күнінің қаржылық көрсеткіштері туралы лездік хабарламалар.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-stock-1-kz.png"
							alt={"оповещения об операциях на кассе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 600,
					tabName: "Қойма",
					desktopTitle: (
						<>
							Шөгусіз және <br />стоп-листтерсіз
						</>
					),
					tabletTitle: <>Шөгусіз және стоп-листтерсіз</>,
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Кез келген өнім үшін қоймада бар-жоғы бойынша шекті орнатуға болады. Мәні сыни бола салысымен
							- ингредиент таусылып жатқанын хабарлаймыз. Осылайша сіз қорларды уақытында
							толтырасыз және сатуды жоғалтпайсыз.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-stock-2-kz.png"
							alt={"уведомления об остатках на складе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Персонал",
					desktopTitle: (
						<>
							Егер біреудің ниеті таза болмаса
						</>
					),
					tabletTitle: <>Если кто-то не&nbsp;чист на&nbsp;руку</>,
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Тапсырысты болдырмау жағдайында сізге қосымшада немесе Telegram-да хабарлама жібереміз.
							Болдырмаулар тым көп болса - бұл кассирдің біліктілігін немесе адалдығын тексеруге себеп.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/notifications-stock-3-kz.png"
								alt={"уведомление об отмене заказа"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'ru-KZ'){
		return {
			features: [
				{
					desktopContentWidth: 600,
					tabName: "Касса",
					desktopTitle: "Первое, что нужно включить",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Моментальные оповещения об&nbsp;операциях на&nbsp;кассе, открытии
							и&nbsp;закрытии смены, финансовых показателях рабочего дня.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-stock-1-kz.png"
							alt={"оповещения об операциях на кассе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 600,
					tabName: "Склад",
					desktopTitle: (
						<>
							Без просадок <br />
							и&nbsp;стоп-листов
						</>
					),
					tabletTitle: <>Без просадок и&nbsp;стоп-листов</>,
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Для любого продукта можно установить порог по&nbsp;наличию
							на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
							сообщим, что ингредиент заканчивается. Так ты&nbsp;вовремя пополнишь
							запасы и&nbsp;не&nbsp;потеряешь продажи.{" "}
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-stock-2-kz.png"
							alt={"уведомления об остатках на складе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Персонал",
					desktopTitle: (
						<>
							Если кто-то не&nbsp;чист <br />
							на&nbsp;руку
						</>
					),
					tabletTitle: <>Если кто-то не&nbsp;чист на&nbsp;руку</>,
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							В&nbsp;случае отмены заказа пришлём тебе уведомление в&nbsp;приложении
							или Telegram. Когда отмен слишком много&nbsp;&mdash; это повод
							проверить квалификацию или честность кассира.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/notifications-stock-3-kz.png"
								alt={"уведомление об отмене заказа"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 600,
				tabName: "Касса",
				desktopTitle: "Первое, что нужно включить",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Моментальные оповещения об&nbsp;операциях на&nbsp;кассе, открытии
						и&nbsp;закрытии смены, финансовых показателях рабочего дня.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/notifications-stock-1.png"
						alt={"оповещения об операциях на кассе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
			},
			{
				desktopContentWidth: 600,
				tabName: "Склад",
				desktopTitle: (
					<>
						Без просадок <br />
						и&nbsp;стоп-листов
					</>
				),
				tabletTitle: <>Без просадок и&nbsp;стоп-листов</>,
				desktopContent: (
					<p className={mediaContent.mediumDescription}>
						Для любого продукта можно установить порог по&nbsp;наличию
						на&nbsp;складе. Как только значение станет критическим&nbsp;&mdash;
						сообщим, что ингредиент заканчивается. Так ты&nbsp;вовремя пополнишь
						запасы и&nbsp;не&nbsp;потеряешь продажи.{" "}
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/notifications-stock-2.png"
						alt={"уведомления об остатках на складе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 600,
				tabName: "Персонал",
				desktopTitle: (
					<>
						Если кто-то не&nbsp;чист <br />
						на&nbsp;руку
					</>
				),
				tabletTitle: <>Если кто-то не&nbsp;чист на&nbsp;руку</>,
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						В&nbsp;случае отмены заказа пришлём тебе уведомление в&nbsp;приложении
						или Telegram. Когда отмен слишком много&nbsp;&mdash; это повод
						проверить квалификацию или честность кассира.
					</p>
				),
				mediaContent: (
					<>
						<StaticImage
							className={mediaContent.image}
							src="./assets/notifications-stock-3.png"
							alt={"уведомление об отмене заказа"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
			},
		] as Array<FeatureGalleryItem>,
	}
}
