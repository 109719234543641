import React from "react"
import { SVGProps } from "react"

const Backdrop = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="877"
		height="574"
		viewBox="0 0 877 574"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.0283203 0H877V573.822C513.447 564.958 4.382 275.866 0.0283203 0Z"
			fill="url(#pattern0)"
			fillOpacity="0.3"
		/>
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width="0.0228057"
				height="0.0357254"
			>
				<use
					xlinkHref="#image0_4109_71024"
					transform="scale(0.000570144 0.00087135)"
				/>
			</pattern>
			<image
				id="image0_4109_71024"
				width="40"
				height="41"
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)

export default Backdrop
