import React, {useEffect} from "react"
import classNames from "classnames"

import FeaturesGallery from "../../../components/_V2/FeaturesGallery"

import styles from "./styles.module.scss"

import { getData } from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";


interface Props {
	className?: string
}

export default function StockFeatures(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	let features = data.features;

	useEffect(() => {
		features = data.features;
	}, [data]);

	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				className={styles.featureGallery}
				theme={"purple-custom-backdrop"}
				features={features}
			/>
		</section>
	)
}
